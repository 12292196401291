import * as React from "react"
import SecondaryLayout from "../components/common/secondary-layout"
import '../styles/pages/index/security.scss'


// markup
const SecurityPage = () => {
  return (
    <SecondaryLayout 
      side={
        <div className="security-main-container--side">
          <h2 className="security-main-container--side__title">Security</h2>
          <p className="security-main-container--side__description">
            Information security, privacy, and data protection are in our DNA. This page provides information about Zenpli’s security program.
          </p>
          <ul className="security-main-container--side__list">
            <li>Program Highlights</li>
            <li>Engaging our Security Team</li>
          </ul>
        </div>
      }
      main={
        <div className="security-main-container--content">
          <h3>Program Highlights</h3>
          <h4>Data Security</h4>
          <p>Zenpli encrypts data at rest and in transit. Access to keys and secrets shall be tightly controlled in-line with industry best practices.</p>
          <h4>Application Security</h4>
          <p>Zenpli uses high-quality monitoring tools to ensure our software development practices are secure. In addition, Zenpli is committed to having third-party experts evaluate our applications through penetration tests to proactively spot any vulnerabilities.</p>
          <h4>Infrastructure Security</h4>
          <p>Zenpli uses Amazon Web Services (AWS) to host our applications, providing a secure back-bone for our products. Also, our applications are deployed in containers.</p>
          <h3>Engaging Our Security Team</h3>
          <p>Our security team is available to address any concern and can be reached at infosec@zenpli.com.</p>
          <h3>Third-party Attestation</h3>
          <p>Zenpli has obtained and maintains SOC2 type I certification. </p>
        </div>
      }
    />
  )
}

export const Head = () => (
  <>
    <title>Zenpli » Security</title>
    <meta name="description" content="Zenpli » Security" />
  </>
)


export default SecurityPage
